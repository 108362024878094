import React from 'react';
import Home from '../home/Home';


function App() {
  return (
      <Home/>
  );
}

export default App;
